<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useElementBounding } from '@vueuse/core'
import { IconArrowRight } from '@cypress-design/vue-icon'
import ProductDescriptionListDashes from '@/components/product-shared/ProductDescriptionListDashes.vue'
import ShikiYaml from '../constants/ci.yaml?shiki'
import CILogos from '@/components/Vue/CILogos.vue'
import BulletWithPadding from './BulletWithPadding.vue'
import { getSectionStore } from '../stores'

const { bottomAvatar, topThreshold } = getSectionStore('app')

const $topBullet = ref<typeof BulletWithPadding>()
const $rightBullet = ref<typeof BulletWithPadding>()
const $grayPath = ref<SVGPathElement>()

const $topBulletEl = computed(() => $topBullet.value?.$el)

const { top: topBulletTop } = useElementBounding($topBulletEl)
const { bottom: bottomPath } = useElementBounding($grayPath)

const dashLength = computed(() => {
  return import.meta.env.SSR ? 0 : Math.min(Math.max(topThreshold.value - topBulletTop.value, 0), 132)
})

const topBulletActive = computed(() => {
  return !!topBulletTop.value && topBulletTop.value < topThreshold.value
})

const rightBulletActive = computed(() => {
  return dashLength.value > 131
})

const localBottomAvatar = computed(() => {
  if (!bottomPath.value) {
    return false
  }
  return bottomPath.value < topThreshold.value
})

watch(
  localBottomAvatar,
  (value) => {
    bottomAvatar.value = value
  },
  { immediate: true },
)
</script>

<template>
  <div class="relative grid grid-cols-[8px_1fr] lg:mt-[156px] lg:grid-cols-[1fr_8px_1fr] lg:gap-[60px]">
    <div class="z-40 my-[32px] hidden overflow-hidden rounded-lg bg-gray-1000 lg:block">
      <code class="block bg-gray-900 px-[16px] py-[8px] text-gray-600">.github/workflows/test.yml</code>
      <ShikiYaml class="p-[16px]" />
    </div>

    <div class="relative">
      <ProductDescriptionListDashes class="absolute left-[50%] top-[-20px] h-[430px]" :active="bottomAvatar" />
      <ProductDescriptionListDashes
        class="absolute left-[50%] top-[-20px] z-10 h-[450px] origin-top transform transition transition-transform duration-500 lg:hidden"
        :class="{
          'scale-y-0': !topBulletActive,
        }"
        active
      />
      <svg
        data-cy="git-timeline-branch"
        width="43"
        height="420"
        viewBox="0 0 43 420"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute left-[2px]"
      >
        <path
          ref="$grayPath"
          d="M 1.5 0 C 1.5 63 42 57.24 42 128 M 42 130 L 42 293 C 42 363 1.5 358 1.5 420"
          class="stroke-gray-100"
          stroke-width="2"
          stroke-linejoin="round"
          stroke-dasharray="4 4"
        />
        <path
          d="M 1.5 0 C 1.5 63 42 57.24 42 128"
          class="stroke-indigo-500"
          stroke-width="2"
          stroke-linejoin="round"
          :stroke-dasharray="`${dashLength} 1000`"
        />
      </svg>
      <BulletWithPadding
        ref="$topBullet"
        data-cy="git-timeline-top-bullet"
        class="left-[-8px] top-[-12px]"
        :active="topBulletActive"
        :current="topBulletActive && !rightBulletActive"
      />
      <BulletWithPadding
        ref="$rightBullet"
        data-cy="git-timeline-right-bullet"
        class="left-[32px] top-[100px]"
        :active="rightBulletActive"
        :current="rightBulletActive && !localBottomAvatar"
      />
    </div>

    <div class="flex h-[410px] items-center">
      <div
        class="top-[12px] z-40 mx-[16px] my-[16px] flex min-h-[290px] flex-col justify-center rounded-lg bg-white/80 px-[32px] py-[24px] shadow lg:h-auto lg:p-[16px] lg:shadow-none"
      >
        <CILogos class="mb-[24px] flex items-center justify-between lg:justify-start lg:gap-[40px]" />
        <h2 class="m-0 text-left font-primary text-[20px] font-semibold leading-[32px]">
          Integrate Cypress with any CI provider
        </h2>
        <p class="mt-[4px] text-[16px] leading-[24px] text-gray-700">
          Easily integrate Cypress with your current CI provider. Run Cypress in CI and you will know as soon as there
          is a failure. We make it simple to run in CI. Use our Docker images or bring your own.
        </p>
        <a
          class="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
          href="/cloud#integrated_workflows"
        >
          Learn more<IconArrowRight />
        </a>
      </div>
    </div>
  </div>
</template>
