<script lang="ts" setup>
import ProductDescriptionListDashes from '@/components/product-shared/ProductDescriptionListDashes.vue'
import type { ProductDescriptionItem } from '@/components/product-shared/DescriptionItems'
import DescriptionContent from './DescriptionContent.vue'
import DescriptionCi from './DescriptionCi.vue'
import Avatar from './Avatar.vue'
import { getSectionStore } from '../stores'
import BulletWithPadding from './BulletWithPadding.vue'

const { bottomAvatar } = getSectionStore('app')

defineProps<{
  items: ProductDescriptionItem[]
}>()
</script>

<template>
  <div data-cy="section-app" class="relative">
    <div
      class="absolute left-[50%] ml-[-1px] mt-[-56px] hidden h-[60px] w-[2px] bg-gradient-to-b from-indigo-500/0 to-indigo-500/100 lg:block"
    />
    <Avatar section-id="app" src="/images/avatar.png" :scale-down="bottomAvatar" />
    <DescriptionContent :items="items" section-id="app" active />
    <ProductDescriptionListDashes class="absolute left-[50%] hidden h-[148px] lg:block" />
    <DescriptionCi />
    <BulletWithPadding class="absolute bottom-[-4px] left-[50%] z-30 -translate-x-3" :active="bottomAvatar" />
    <ProductDescriptionListDashes
      class="absolute bottom-[-64px] left-[4px] z-10 h-[50px] lg:bottom-[-40px] lg:left-[50%]"
      :active="bottomAvatar"
    />
    <div
      class="absolute bottom-[-64px] left-[4px] z-20 ml-[-2px] h-[54px] w-[4px] bg-gradient-to-b from-white/0 to-white/100 lg:bottom-[-40px] lg:left-[50%]"
    />
  </div>
</template>
